import React from 'react'
import { Link } from 'gatsby'

const SecondPage = () =>
  <div>
    <h1>Hi people</h1>
    <p>Welcome to page 2</p>
    <Link to="/">Go back to the homepage</Link>
  </div>

export default SecondPage
